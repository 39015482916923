import {UploadRequirement} from "./uploadRequirement.enum";
import {GueltigkeitsdauerUnit} from "./gueltigkeitsdauerUnit.enum";
import {ErinnerungZeit} from "./erinnerungZeit.enum";
import {INachweis} from "./INachweis.interface";

export interface INachweisvorlage {
  id: number
  name: string
  uploadRequirement: UploadRequirement
  beschreibung: string,
  gueltigkeitsdauerVal: number
  gueltigkeitsdauerUnit: GueltigkeitsdauerUnit | '',
  erinnerungZeit: ErinnerungZeit,
  pflichtfeldMoeglich: boolean
  disabled: boolean
}


export function isNachweisvorlage(obj: Object): obj is INachweis {
  function d(value: string): boolean {
    return value !== undefined;
  }

  return obj &&
    d(obj['id']) &&
    d(obj['name']) &&
    d(obj['uploadRequirement']) &&
    d(obj['beschreibung']) &&
    d(obj['gueltigkeitsdauerVal']) &&
    d(obj['gueltigkeitsdauerUnit']) &&
    d(obj['erinnerungZeit']) &&
    d(obj['pflichtfeldMoeglich']) &&
    d(obj['disabled']);
}
