import {GueltigkeitsdauerUnit, subtractGueltigkeitsdauer} from "./gueltigkeitsdauerUnit.enum";

export enum ErinnerungZeit {
  NONE = "NONE",
  ON_TIME = "ON_TIME",
  WEEK_1 = "WEEK_1",
  WEEK_2 = "WEEK_2",
  MONTH_1 = "MONTH_1",
  MONTH_2 = "MONTH_2",
}

export function getErinnerungDate(end: Date, time: ErinnerungZeit) {
  if(time === ErinnerungZeit.NONE) {
    return new Date(9999, 12, 31, 23, 59, 59);
  }
  const converted = erinnerungZeitToGueltigkeitsdauer(time);
  return subtractGueltigkeitsdauer(end, converted.unit, converted.val);
}

export function erinnerungZeitToGueltigkeitsdauer(val: ErinnerungZeit): { unit: GueltigkeitsdauerUnit, val: number } {
  switch (val) {
    case ErinnerungZeit.NONE:
      return {unit: GueltigkeitsdauerUnit.JAHR, val: -9999};
    case ErinnerungZeit.ON_TIME:
      return {unit: GueltigkeitsdauerUnit.TAG, val: 0};
    case ErinnerungZeit.WEEK_1:
      return {unit: GueltigkeitsdauerUnit.WOCHE, val: 1};
    case ErinnerungZeit.WEEK_2:
      return {unit: GueltigkeitsdauerUnit.WOCHE, val: 2};
    case ErinnerungZeit.MONTH_1:
      return {unit: GueltigkeitsdauerUnit.MONAT, val: 1};
    case ErinnerungZeit.MONTH_2:
      return {unit: GueltigkeitsdauerUnit.MONAT, val: 2};
    default:
      throw new Error("Unknown ErinnerungZeit value: " + val);
  }
}
