import {Injectable} from '@angular/core';
import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {INachweisvorlage} from "../models/INachweisvorlage.interface";

@Injectable({
  providedIn: 'root'
})
export class NachweisvorlageService {

  private revision: number = -1;
  private vorlagen: INachweisvorlage[] = [];

  constructor(private backend: BackendService,
              private sessionService: SessionService) {
  }

  public updateData(): Promise<INachweisvorlage[]> {
    const data = this.backend.sendRequest("getNachweisvorlage", {
      sessionId: this.sessionService.session.sessionId
    }, false);

    const result = data.then(d => {
      if (d === null) {
        return [];
      }

      return d;
    }).catch(err => {
      console.log("Failed to load Nachweisvorlagen");
      console.log(err);
    }).then(d => {
      this.vorlagen = d as unknown as INachweisvorlage[];
      this.revision++;
      return this.vorlagen;
    });
    return result;
  }

  public getNachweisvorlageList(): INachweisvorlage[] {
    return this.vorlagen;
  }

  public getNachweisvorlage(id: number): INachweisvorlage {
    return this.vorlagen.find(tn => tn.id === id);
  }

  public updateVorlage(vorlage: INachweisvorlage, ignoreReload?: boolean): Promise<string> {
    try {
      console.log("updateVorlageToJSON: ");
      console.log(BackendService.toJsonObj(vorlage));
      console.log("updateVorlageObject: ")
      console.log(vorlage);
      const data = this.backend.sendRequest("updateNachweisvorlage", {
        nachweisvorlage: BackendService.toJsonObj(vorlage),
        sessionId: this.sessionService.session.sessionId
      }, !ignoreReload as unknown as true);
      return data.then(msg => {
        return new Promise<string>((resolve, reject) => {
          if (msg.success === false) {
            reject(msg.error);
          }
          resolve("Änderungen gespeichert");
        })
      });
    } catch (e) {
      return Promise.reject(e.message)
    }
  }

  /**
   * ID wird nicht mit geschrieben
   */
  public async createVorlage(vorlage: INachweisvorlage, ignoreReload = false): Promise<string> {
    const createResult = await this.backend.sendRequest("createNachweisvorlage", {
      nachweisvorlage: BackendService.toJsonObj(vorlage),
      sessionId: this.sessionService.session.sessionId
    }, !ignoreReload as unknown as true);

    if(createResult.success){
      return "Nachweisvorlage erfolgreich angelegt";
    } else {
      throw createResult.error;
    }
  }

  public getRevision(): number {
    return this.revision;
  }
}
