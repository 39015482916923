import { Injectable } from '@angular/core';
import { NutzerService } from './nutzer.service';
import { StammService } from './stamm.service';
import { TeilnehmerService } from './teilnehmer.service';
import { VeranstaltungenService } from './veranstaltungen.service';
import { EventQueueService } from './event-queue-service.service';
import {NachweisService} from "./nachweis.service";
import {NachweisvorlageService} from "./nachweisvorlage.service";

@Injectable({
  providedIn: 'root'
})
export class CheatServiceService {

  private _isLoading = false;

  constructor(
    private nutzerService: NutzerService,
    private stammService: StammService,
    private teilnehmerService: TeilnehmerService,
    private veranstaltungService: VeranstaltungenService,
    private nachweiseService: NachweisService,
    private nachweisvorlagenService: NachweisvorlageService,
    private eventQueue: EventQueueService
  ) {
    (window as any).nutzerService = this.nutzerService;
    (window as any).stammService = this.stammService;
    (window as any).teilnehmerService = this.teilnehmerService;
    (window as any).veranstaltungService = this.veranstaltungService;
    (window as any).nachweiseService = this.nachweiseService;
    (window as any).nachweisvorlagenService = this.nachweisvorlagenService;


    eventQueue.getObservable().subscribe((msg) => {
      switch (msg) {
        case "permissionChange":
        case "relaod":
          this.update();
          break;
        default:
          break;
      }
    })
  }


  public async update() {
    this._isLoading = true;
    return new Promise((resolve, reject) => {
      const serviceCount = 6;
      let i = 0;

      const serviceInitDone = () => {
        i++;
        if (i === serviceCount) {
          this.eventQueue.fireEvent("initDone");
          this._isLoading = false;
          resolve();
        }
      };

      this.nutzerService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.stammService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.teilnehmerService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.veranstaltungService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.nachweiseService.updateData().then(serviceInitDone).catch((err) => reject(err));
      this.nachweisvorlagenService.updateData().then(serviceInitDone).catch((err) => reject(err));
    });
  }

  public get isLoading(){
    return this._isLoading;
  }
}
