<div class="row">
  <app-nav-settings (selectedElement)="selectedElement=$event" class="col-12 col-lg-3 navigation"></app-nav-settings>

  <div [ngSwitch]="selectedElement" class="col-12 col-lg-9 inhalt">
    <div class="row">
    <div class="col-1"></div>
    <app-view-user-settings *ngSwitchCase="'Benutzer'" [echterNutzer]="nutzer" class="col-10"></app-view-user-settings>
    <app-view-stamm-settings *ngSwitchCase="'Stamm'" class="col-10"></app-view-stamm-settings>
    <app-view-user-management *ngSwitchCase="'Benutzerverwaltung'" class="col-10"></app-view-user-management>
    <app-view-nachweisvorlagen *ngSwitchCase="'Nachweisvorlagen'" class="col-10"></app-view-nachweisvorlagen>
    <app-view-stats *ngSwitchCase="'Statistics'" class="col-10"></app-view-stats>
    <div *ngSwitchDefault>Default</div>
    <div class="col-1"></div>
    </div>
  </div>
</div>
