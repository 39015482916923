import {Component, Input, OnInit} from '@angular/core';

export enum PreviewFileType {
  PDF, IMAGE, TEXT, UNKNOWN
}

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

  @Input() file: Promise<string>;
  @Input() fileName?: string;
  @Input() fileType: PreviewFileType = PreviewFileType.UNKNOWN;

  private blobCache: Promise<string>;

  constructor() { }

  ngOnInit(): void {
    if(this.fileName && this.fileType === PreviewFileType.UNKNOWN) {
      const match = /.*\.([^.]+)$/.exec(this.fileName.toLowerCase());
      const extension = match ? match[1] : "--";
      switch (extension) {
        case "pdf":
          this.fileType = PreviewFileType.PDF;
          break;
        case "jpeg":
        case "jpg":
        case "xbm":
        case "tif":
        case "jfif":
        case "ico":
        case "tiff":
        case "gif":
        case "svg":
        case "webp":
        case "svgz":
        case "bmp":
        case "pjp":
        case "apng":
        case "pjpeg":
        case "avif":
        case "png":
          this.fileType = PreviewFileType.IMAGE;
          break;
        case "txt":
        case "csv":
          this.fileType = PreviewFileType.TEXT;
          break;
        default:
          this.fileType = PreviewFileType.UNKNOWN;
          break;
      }
    }
  }

  get fileAsBlob() {
    if(!this.blobCache) {
      this.blobCache = this.file.then(f => this.base64ToBlobUrl(f));
    }
    return this.blobCache;
  }

  private base64ToBlobUrl(base64: string, sliceSize = 512) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    let contentType: string;
    switch (this.fileType) {
      case PreviewFileType.PDF:
        contentType = 'application/pdf';
        break;
      case PreviewFileType.IMAGE:
        contentType = 'image/*';  // You may specify a more specific image type if needed
        break;
      case PreviewFileType.TEXT:
        contentType = 'text/plain';
        break;
      default:
        contentType = 'application/octet-stream';
        break;
    }


    const blob = new Blob(byteArrays, {type: contentType});
    const url = URL.createObjectURL(blob);
    console.log(url);
    return url;
  }

  public readonly PreviewFileType = PreviewFileType;
}
