<div class="container pt-2">
  <h2 class="text-center primaryFontColor">Pflichtfelder</h2>

  <p class="text-center text-lg-left">F&uuml;r die Anmeldung an dieser Veranstaltung sind folgende Informationen des Teilnehmers immer anzugeben.</p>
  <ul class="list-group my-2">
    <li *ngFor="let rf of registerForm" class="list-group-item">
      <span *ngIf="rf.size" [class]="rf.size === 'lg' ? 'font-weight-bold' : 'font-weight-light'">
          {{rf.msg}}
      </span>
      <span *ngIf="!rf.size">
        <input type="checkbox" [(ngModel)]="rf.selected" style="transform: scale(2);" class="mx-2">
        {{rf.title}}
        <span class="float-right primaryFontColor" *ngIf="rf.type === 'boolean'"></span>
        <!--<span class="float-right primaryFontColor" *ngIf="rf.type === 'boolean'">(Muss beim Teilnehmer aktiv sein)</span>-->
      </span>
    </li>
  </ul>
  <ul class="list-group my-2">
    <li class="list-group-item font-weight-bold">
      Benötigte Nachweise
    </li>
    <li *ngFor="let v of vorlagen" class="list-group-item d-flex justify-content-between">
      <span class="flex-grow-0">
        <input type="checkbox" [(ngModel)]="v.selected" style="transform: scale(2);" class="mx-2">
        {{v.name}}
        <span class="float-right primaryFontColor"></span>
      </span>
      <span class="flex-grow-1 text-truncate text-muted text-right ml-3">
        {{v.beschreibung}}
      </span>
    </li>
  </ul>
  <div style="height:55px;"></div>
  <div class="fixed-bottom secondaryBgColor d-flex justify-content-center justify-content-lg-end">
    <button class="btn pcButton m-2" (click)="save()">Speichern</button>
  </div>
</div>
