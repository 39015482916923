import { INutzer } from './INutzer.interface';
import { IStamm } from './IStamm.interface';
import { ITeilnehmer } from './ITeilnehmer.interface';

export interface IVeranstaltung {
  id: number;
  titel: string;
  anfangsdatum: Date;
  enddatum: Date;
  ort: string;
  anmeldeschluss: Date;
  max_teilnehmeranzahl: number;
  veranstaltungsmanager: INutzer[];
  veranstalter: IStamm | null; // Null, wenn der Stamm gelöscht wurde
  staemme: IStamm[];
  kurzbeschreibung: string;
  veranstaltungstyp: string;
  teilnehmer: ITeilnehmer[];
  mitarbeiter_preis: number;
  ausfallgebuehr: number;
  preis: number;
  maximale_einladungen: number;
  kursleitung: string;
  anfangsuhrzeit: string;
  enduhrzeit: string;
  registerForm: string;
  pflichtfelder: string;
  nachweise: string;
}

export function isVeranstaltung(obj: Object): obj is IVeranstaltung {
  function d(value: string): boolean {
    return value !== undefined;
  }

  return obj &&
    d(obj['id']) &&
    d(obj['titel']) &&
    d(obj['anfangsdatum']) &&
    d(obj['enddatum']) &&
    d(obj['ort']) &&
    d(obj['anmeldeschluss']) &&
    d(obj['max_teilnehmeranzahl']) &&
    d(obj['veranstaltungsmanager']) &&
    d(obj['veranstalter']) &&
    d(obj['staemme']) &&
    d(obj['kurzbeschreibung']) &&
    d(obj['veranstaltungstyp']) &&
    d(obj['teilnehmer']) &&
    d(obj['mitarbeiter_preis']) &&
    d(obj['ausfallgebuehr']) &&
    d(obj['preis']) &&
    d(obj['maximale_einladungen']) &&
    d(obj['kursleitung'])&&
    d(obj['anfangsuhrzeit'])&&
    d(obj['enduhrzeit']);

}

export function getVeranstaltungsSearchCategories(): {display: string, key: string, type: string}[] {
  return [
    { display: "Veranstaltungsinfos", key: "Veranstaltungsinfos", type: "CAT" },
    { display: "Titel", key: "titel", type: "string" },
    { display: "Ort", key: "ort", type: "string" },
    { display: "Kurzbeschreibung", key: "kurzbeschreibung", type: "string" },
    { display: "Anfangsdatum", key: "anfangsdatum", type: "Date" },
    { display: "Anfangsuhrzeit", key: "anfangsuhrzeit", type: "String" },
    { display: "Enddatum", key: "enddatum", type: "Date" },
    { display: "Enduhrzeit", key: "enduhrzeit", type: "String" },
    { display: "Anmeldeschluss", key: "anmeldeschluss", type: "Date" },
    { display: "Maximale Teilnehmeranzahl", key: "max_teilnehmeranzahl", type: "number" },
    { display: "Veranstaltungstyp", key: "veranstaltungstyp", type: "string" },

    { display: "Veranstallter", key: "Veranstallter", type: "CAT" },
    { display: "Veranstalter", key: "veranstalter", type: "IStamm" },
    { display: "Veranstaltungsmanager", key: "veranstaltungsmanager", type: "INutzer[]" },
    { display: "Preis", key: "preis", type: "number" },
    { display: "Ausfallgebuehr", key: "ausfallgebuehr", type: "number" },
    { display: "Mitarbeiterpreis", key: "mitarbeiter_preis", type: "number" },
    { display: "Kursleitung", key: "kursleitung", type: "string" },

    { display: "Sonstiges", key: "Sonstiges", type: "CAT" },
    { display: "Eingeladene Stämme", key: "staemme", type: "IStamm[]" },
    { display: "Teilnehmer", key: "teilnehmer", type: "ITeilnehmer[]" },
    { display: "ID", key: "id", type: "number" },
  ];
}
