export enum GueltigkeitsdauerUnit {
  TAG="TAG", WOCHE="WOCHE", MONAT="MONAT", JAHR="JAHR"
}

export function addGueltigkeitsdauer(date: Date, val: GueltigkeitsdauerUnit, count: number = 1): Date {
  const newDate = new Date(date);

  switch (val) {
    case GueltigkeitsdauerUnit.TAG:
      newDate.setDate(newDate.getDate() + count); // Add count number of days
      break;
    case GueltigkeitsdauerUnit.WOCHE:
      newDate.setDate(newDate.getDate() + (7 * count)); // Add count number of weeks
      break;
    case GueltigkeitsdauerUnit.MONAT:
      newDate.setMonth(newDate.getMonth() + count); // Add count number of months
      break;
    case GueltigkeitsdauerUnit.JAHR:
      newDate.setFullYear(newDate.getFullYear() + count); // Add count number of years
      break;
    default:
      return date;
  }

  return newDate;
}

export function subtractGueltigkeitsdauer(date: Date, val: GueltigkeitsdauerUnit, count: number = 1): Date {
  const newDate = new Date(date);

  switch (val) {
    case GueltigkeitsdauerUnit.TAG:
      newDate.setDate(newDate.getDate() - count); // Subtract count number of days
      break;
    case GueltigkeitsdauerUnit.WOCHE:
      newDate.setDate(newDate.getDate() - (7 * count)); // Subtract count number of weeks
      break;
    case GueltigkeitsdauerUnit.MONAT:
      newDate.setMonth(newDate.getMonth() - count); // Subtract count number of months
      break;
    case GueltigkeitsdauerUnit.JAHR:
      newDate.setFullYear(newDate.getFullYear() - count); // Subtract count number of years
      break;
    default:
      return date;
  }

  return newDate;
}
