<div class="preview-frame w-100 py-2">
  <ng-container *ngIf="file | async as file; else loading">
    <ng-container [ngSwitch]="fileType">
      <ng-container *ngSwitchCase="PreviewFileType.PDF">
        <ng-container *ngIf="fileAsBlob | async as blob; else loading">
          <iframe [src]="blob | safe" width="100%" height="600px"></iframe>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="PreviewFileType.IMAGE">
        <img [src]="file | safe" alt="Image preview" style="max-width: 100%" class="mx-auto d-block">
      </ng-container>
      <ng-container *ngSwitchCase="PreviewFileType.TEXT">
        <pre>{{ file }}</pre>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  Datei wird geladen <i class="fas fa-circle-notch fa-spin"></i>
</ng-template>
