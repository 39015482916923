
<datalist id="schwimmabzeichenList">
  <option>Seepferdchen</option>
  <option>Bronze</option>
  <option>Silber</option>
  <option>Gold</option>
</datalist>
<div class="root rounded d-lg-block d-none ">
  <!-------------------------------------Erste Zeile------------------------------------------------>
  <div class="row height1 ">
    <!------------------Geschlecht-Icons-------------->

    <div class="col-1 align-self-center bigfont text-center">
      <img src="{{tn.stamm.icon}}" [ngbTooltip]="tn.stamm | stammName" class="stammIcon"/>
    </div>
    <!------------------Name------------------------->
    <div class="col-7 align-self-center">
      <div class="row">
        <div class="col-6">
          <input type="text" class="form-control" [(ngModel)]="tn.vorname" ngbTooltip="Vorname" placeholder="Vorname*">
        </div>
        <div class="col-6">
          <input type="text" class="form-control" [(ngModel)]="tn.nachname" ngbTooltip="Nachname"
                 placeholder="Nachname*">
        </div>
      </div>
    </div>

    <!------------------Geburtsdatum-------------->
    <div class="col-2 text-center align-self-center" ngbTooltip="Geburtsdatum">
      <div class="input-group ">
        <input class="form-control" placeholder="tt.mm.jjjj*"
               name="dp" [(ngModel)]="birthDate" ngbDatepicker #d="ngbDatepicker">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><i
            class="far fa-calendar-alt"></i></button>
        </div>
      </div>
    </div>

    <!------------------Alter-------------->
    <div class="col-2 text-center align-self-center bigfont"
         ngbTooltip="Alter">{{(tn.geburtsdatum | ageFilter) || "---"}}</div>
  </div>


  <!-------------------------------------Zweite Zeile------------------------------------------------>
  <div class="px-3 d-flex flex-row height2 ">
    <!------------------Adresse-------------->
    <div class="flex-grow-1 mr-3  ">
      <div class="row inputRow">
        <input type="text" class="form-control" [(ngModel)]="tn.zusatz" placeholder="Adresszusatz">
      </div>
      <div class="row inputRow">
        <div class="col-9 inputColLeft">
          <input type="text" class="form-control" [(ngModel)]="tn.strasse" placeholder="Straße*">
        </div>
        <div class="col-3 inputColRight">
          <input type="text" class="form-control" [(ngModel)]="tn.hausnummer" placeholder="Hausnummer*">
        </div>
      </div>
      <div class="row inputRow">
        <div class="col-4 inputColLeft">
          <input type="text" class="form-control" [(ngModel)]="tn.postleitzahl" placeholder="Postleitzahl*">
        </div>
        <div class="col-8 inputColRight">
          <input type="text" class="form-control" [(ngModel)]="tn.ort" placeholder="Ort*">
        </div>
      </div>
    </div>
    <!------------------Icons-------------->
    <div class="flex-grow-0 text-center h-100 iconRow  text-center">
      <div class="row height1 mt-3">
        <app-icon-switch [options]="mitarbeiterOption" [(value)]="tn.mitarbeiterstatus"></app-icon-switch>
        <app-icon-switch [options]="genderOptions" [(value)]="tn.geschlecht" class="ml-3"></app-icon-switch>
      </div>
      <div class="row height1 ">
        <app-icon-switch [options]="veganOption" [(value)]="veganState" ></app-icon-switch>
      </div>
    </div>
  </div>

  <!------------------Kommunikations-------------->
  <div class="row height1 bigfont">
    <div class="col-6 text-center align-self-center input-group bigfont">
      <div class="input-group-append">
        <span class="input-group-text"><app-icon [icon]="Icon.HANDYNUMMER"></app-icon></span>
      </div>
      <input type="tel" class="form-control input-group-size" [(ngModel)]="tn.handynummer" id="mobil"
             placeholder="Telefon/Mobil">
    </div>
    <div class="col-6 text-center input-group align-self-center ">
      <div class="input-group-append">
        <span class="input-group-text"><app-icon [icon]="Icon.E_MAIL_ADRESSE"></app-icon></span>
      </div>
      <input type="email" class="form-control input-group-size" [(ngModel)]="tn.e_mail_adresse" placeholder="E-Mail">
    </div>
  </div>

  <br>


  <!------------------Grüne Zeilen---------------------------------------------------------------------------------------->
  <div class="" id="kontaktPerson">
    <!------------------Kontaktperson-------------->
    <div class="card">
      <div class="card-header collapsed" data-toggle="collapse" href="#kontakt">

        <i class="fas fa-caret-up arrowUp"></i>
        <i class="fas fa-caret-down arrowDown"></i>
        Personensorgeberechtigter<span class="pflichtfeld"
                                       *ngIf="tn.geburtsdatum.getTime()+(18*365*24*3600*1000) > now"></span>
      </div>
      <div id="kontakt" class="collapse " data-parent="#kontaktPerson">
        <button class="btn btn-secondary ml-3 mt-3" (click)="copyTnAddrToPer()">Adresse vom Kind kopieren</button>
        <div class="card-body">
          <div class="row heightEltern">
            <div class="col-6">
              <input type="text" class="form-control input-group-size" [(ngModel)]="tn.per_vorname"
                     placeholder="Vorname">
            </div>
            <div class="col-6">
              <input type="text" class="form-control input-group-size" [(ngModel)]="tn.per_nachname"
                     placeholder="Nachname">
            </div>
          </div>
          <div class="row heightEltern">
            <div class="col-6">
              <input type="text" class="form-control input-group-size" [(ngModel)]="tn.per_zusatz" placeholder="Zusatz">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text"><app-icon [icon]="Icon.PER_TELEFONNUMMER"></app-icon></span>
              </div>
              <input type="tel" class="form-control input-group-size" [(ngModel)]="tn.per_telefonnummer"
                     placeholder="Telefon">
            </div>
          </div>
          <div class="row heightEltern">
            <div class="col-4">
              <input type="text" class="form-control input-group-size" [(ngModel)]="tn.per_strasse"
                     placeholder="Straße">
            </div>
            <div class="col-2">
              <input type="text" class="form-control input-group-size" [(ngModel)]="tn.per_hausnummer"
                     placeholder="Hausnummer">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text"><app-icon [icon]="Icon.PER_HANDYNUMMER"></app-icon></span>
              </div>
              <input type="tel" class="form-control input-group-size" [(ngModel)]="tn.per_handynummer"
                     placeholder="Handynummer">
            </div>
          </div>
          <div class="row heightEltern">
            <div class="col-2">
              <input type="text" class="form-control input-group-size" [(ngModel)]="tn.per_postleitzahl"
                     placeholder="PLZ">
            </div>
            <div class="col-4">
              <input type="text" class="form-control input-group-size" [(ngModel)]="tn.per_ort" placeholder="Ort">
            </div>
            <div class="col-6 input-group">
              <div class="input-group-append">
                <span class="input-group-text"><app-icon [icon]="Icon.PER_E_MAIL_ADRESSE"></app-icon></span>
              </div>
              <input type="email" class="form-control input-group-size" [(ngModel)]="tn.per_e_mail_adresse"
                     placeholder="E-Mail">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-----------------------------Stamm-------------------------------------->
    <div class="card" *ngIf="session.currentPermission === 'admin' || session.currentPermission === 'super user' || session.nutzer.stamme.length > 1">
      <div class="card-header collapsed" data-toggle="collapse" href="#stamm">
        <i class="fas fa-caret-up arrowUp"></i> <i class="fas fa-caret-down arrowDown"></i> Stamm
      </div>
      <div id="stamm" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          <select class="form-control" [(ngModel)]="tn.stamm">
            <option *ngFor="let s of nutzerStaemme" [ngValue]="s">{{s.name + (s.title ? (" (" + s.title + ")") : "")}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-----------------------------Medizin + Krankheiten-------------------------------------->
    <div class="card">

      <div class="card-header collapsed" data-toggle="collapse" href="#medizin">
        <i class="fas fa-caret-up arrowUp"></i>
        <i class="fas fa-caret-down arrowDown"></i> Medikamenteneinnahme & Krankheiten
        <app-icon class="float-right" [icon]="Icon.KRANKHEITEN" [disabled]="!tn.krankheiten"></app-icon>
        <app-icon class="float-right" [icon]="Icon.MEDIZINISCHE_HINWEISE" [disabled]="!tn.medizinische_hinweise"></app-icon>
      </div>
      <div id="medizin" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          <h5>Bitte nur relevante Daten, die für eine Veranstalltung dienlich sind, eintragen. Datenschutzrichtlinie beachten!</h5>
          <h5>Medizinische Hinweise</h5>
          <textarea [(ngModel)]="tn.medizinische_hinweise" class="form-control" rows="5"></textarea>
          <h5>Krankheiten</h5>
          <textarea [(ngModel)]="tn.krankheiten" class="form-control" rows="5"></textarea>
        </div>
      </div>
    </div>

    <!-----------------------------Ernährungsbesonderheiten-------------------------------------->
    <div class="card">
      <div class="card-header collapsed" data-toggle="collapse" href="#ernaehrungsbesonderheiten">
        <i class="fas fa-caret-up arrowUp"></i>
        <i class="fas fa-caret-down arrowDown"></i>
        Ernährungsbesonderheiten
        <app-icon class="float-right" [icon]="Icon.ERNAEHRUNGSBESONDERHEITEN" [disabled]="!tn.ernaehrungsbesonderheiten"></app-icon>
      </div>
      <div id="ernaehrungsbesonderheiten" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          <h5>Bitte nur relevante Daten, die für eine Veranstalltung dienlich sind, eintragen. Datenschutzrichtlinie beachten!</h5>
          <textarea [(ngModel)]="tn.ernaehrungsbesonderheiten" class="form-control" rows="5"></textarea>
        </div>
      </div>
    </div>

    <!-----------------------------schwimmabzeichen-------------------------------------->
    <div class="card">
      <div class="card-header collapsed" data-toggle="collapse" href="#schwimmabzeichen">
        <i class="fas fa-caret-up arrowUp"></i> <i class="fas fa-caret-down arrowDown"></i> Schwimmabzeichen
      </div>
      <div id="schwimmabzeichen" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          <input type="text" class="form-control" [(ngModel)]="tn.schwimmabzeichen"
                 placeholder="Bronze / Silber / Gold" list="schwimmabzeichenList">
        </div>
      </div>
    </div>

    <!-----------------------------Bemerkung-------------------------------------->
    <div class="card">
      <div class="card-header collapsed" data-toggle="collapse" href="#bemerkung">
        <i class="fas fa-caret-up arrowUp"></i> <i class="fas fa-caret-down arrowDown"></i> Bemerkung
      </div>
      <div id="bemerkung" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          <h5>Bitte nur relevante Daten, die für eine Veranstalltung dienlich sind, eintragen. Datenschutzrichtlinie beachten!</h5>
          <textarea [(ngModel)]="tn.weitere_bemerkungen" class="form-control" rows="5"></textarea>
        </div>
      </div>
    </div>


    <!-----------------------------Nachweise-------------------------------------->
    <div class="card">
      <div class="card-header collapsed" data-toggle="collapse" href="#nachweise">
        <i class="fas fa-caret-up arrowUp"></i> <i class="fas fa-caret-down arrowDown"></i> Nachweise
      </div>
      <div id="nachweise" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          <ng-template #createNachweise>
            <div>Nachweise können erst nach dem Speichern des Teilnehmers eingetragen werden.</div>
          </ng-template>
          <ng-container *ngIf="!!tn.id; else createNachweise">
            <app-edit-nachweise [tn]="tn"></app-edit-nachweise>
          </ng-container>
        </div>
      </div>
    </div>



    <!-----------------------------Erlaubnisse-------------------------------------->
    <div class="card">
      <div class="card-header collapsed" data-toggle="collapse" href="#erlaubnisse">
        <i class="fas fa-caret-up arrowUp"></i> <i class="fas fa-caret-down arrowDown"></i> Erlaubnisse
      </div>
      <div id="erlaubnisse" class="collapse" data-parent="#kontaktPerson">
        <div class="card-body">
          <ng-container *ngFor="let i of iconList; last as isLast">
            <div class="d-flex flex-row align-items-center" >
              <div class="flex-grow-1">
                {{iconMeta.get(i.icon)[tn[i.key]?2:3]}}
              </div>
              <div class="flex-grow-0">
                <app-icon-switch [options]="i.options" [(value)]="tn[i.key]"></app-icon-switch>
              </div>

            </div>
            <hr *ngIf="!isLast">
          </ng-container>
        </div>
      </div>
    </div>


    <!-----------------------------ICONS-------------------------------------->
    <div class="row rounded height1 bigfont iconsContainer justify-content-around">
      <div class="text-center align-self-center"
           *ngFor="let i of iconList">
        <app-icon [icon]="i.icon" [strike]="i.options[0].value !== tn[i.key]" ></app-icon>
      </div>
    </div>

  </div>
</div>

<!-----------------------------------------Mobile------------------------------------->
<div *ngIf="tn" class="d-lg-none p-4">
  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
    <h3 class="text-center primaryFontColor">Allgemein</h3>
    <hr>
    <img src="{{tn.stamm.icon}}" [ngbTooltip]="tn.stamm | stammName" class="stammIcon bigfont mx-auto d-block"/>
    <hr>

    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Vorname</label>
      <input type="text" class="form-control" [(ngModel)]="tn.vorname" ngbTooltip="Vorname" placeholder="">
    </div>

    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Nachname</label>
      <input type="text" class="form-control" [(ngModel)]="tn.nachname" ngbTooltip="Nachname" placeholder="">
    </div>
    <div class="from-group">
      <label class="primaryFontColor pflichtfeld">Geburtsdatum</label>
      <input class="form-control" placeholder="tt.mm.jjjj*" name="dp" [(ngModel)]="birthDate" ngbDatepicker
             #d="ngbDatepicker" (click)="d.toggle()">
      <span class="primaryFontColor text-italic">{{(tn.geburtsdatum | ageFilter) || "---"}} Jahre</span>
    </div>
    <hr>
    <div class="justify-content-around  d-flex flex-wrap">
      <app-icon-switch [options]="mitarbeiterOption" [(value)]="tn.mitarbeiterstatus"></app-icon-switch>
      <app-icon-switch [options]="genderOptions" [(value)]="tn.geschlecht" class="ml-3"></app-icon-switch>
      <app-icon-switch [options]="veganOption" [(value)]="veganState" class="ml-3" ></app-icon-switch>
    </div>
    <hr>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Adresszusatz</label>
      <input type="text" class="form-control" [(ngModel)]="tn.zusatz" ngbTooltip="Adresszusatz" placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Straße</label>
      <input type="text" class="form-control" [(ngModel)]="tn.strasse" ngbTooltip="Straße" placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Hausnummer</label>
      <input type="text" class="form-control" [(ngModel)]="tn.hausnummer" ngbTooltip="Hausnummer" placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Postleitzahl</label>
      <input type="number" step="1" inputmode="numeric" max="99999" class="form-control" [(ngModel)]="tn.postleitzahl"
             ngbTooltip="Postleitzahl" placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Ort</label>
      <input type="text" class="form-control" [(ngModel)]="tn.ort" ngbTooltip="Ort" placeholder="">
    </div>
    <hr>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Telefon / Mobil</label>
      <input type="tel" class="form-control" [(ngModel)]="tn.handynummer" ngbTooltip="Telefonnummer / Mobil"
             placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">E-Mail</label>
      <input type="email" inputmode="email" class="form-control" [(ngModel)]="tn.e_mail_adresse" ngbTooltip="E-Mail"
             placeholder="">
    </div>
  </div>

  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
    <h3 class="text-center primaryFontColor">Kontaktperson</h3>
    <hr>
    <button class="btn btn-secondary mx-auto my-2" (click)="copyTnAddrToPer()">Adresse vom Kind kopieren</button>
    <hr>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Vorname</label>
      <input type="text" class="form-control" [(ngModel)]="tn.per_vorname" ngbTooltip="Vorname" placeholder="">
    </div>

    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Nachname</label>
      <input type="text" class="form-control" [(ngModel)]="tn.per_nachname" ngbTooltip="Nachname" placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Adresszusatz</label>
      <input type="text" class="form-control" [(ngModel)]="tn.per_zusatz" ngbTooltip="Adresszusatz" placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Straße</label>
      <input type="text" class="form-control" [(ngModel)]="tn.per_strasse" ngbTooltip="Straße"
             placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Hausnummer</label>
      <input type="text" class="form-control" [(ngModel)]="tn.per_hausnummer" ngbTooltip="Hausnummer"
             placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Postleitzahl</label>
      <input type="number" step="1" inputmode="numeric" max="99999" class="form-control"
             [(ngModel)]="tn.per_postleitzahl" ngbTooltip="Postleitzahl" placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Ort</label>
      <input type="text" class="form-control" [(ngModel)]="tn.per_ort" ngbTooltip="Ort" placeholder="">
    </div>
    <hr>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Telefon</label>
      <input type="tel" class="form-control" [(ngModel)]="tn.per_telefonnummer" ngbTooltip="Telefonnummer"
             placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">Mobil</label>
      <input type="tel" class="form-control" [(ngModel)]="tn.per_handynummer" ngbTooltip="Mobil" placeholder="">
    </div>
    <div class="form-group">
      <label class="primaryFontColor pflichtfeld">E-Mail</label>
      <input type="email" inputmode="email" class="form-control" [(ngModel)]="tn.per_e_mail_adresse" ngbTooltip="E-Mail"
             placeholder="">
    </div>
  </div>

  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
    <h3 class="text-center primaryFontColor">Medi­ka­mente & Krank­heiten</h3>
    <hr>
    <div class="d-flex justify-content-center bigfont">
      <app-icon [icon]="Icon.MEDIZINISCHE_HINWEISE" class="mx-2" [disabled]="!tn.medizinische_hinweise"></app-icon>
      <app-icon [icon]="Icon.KRANKHEITEN" class="mx-2" [disabled]="!tn.krankheiten"></app-icon>
    </div>
    <hr>
    <h6 class="primaryFontColor">Bitte nur relevante Daten, die für eine Veranstalltung dienlich sind, eintragen. Datenschutzrichtlinie beachten!</h6>
    <div class="form-group">
      <label class="primaryFontColor">Medikamente
      </label>

      <textarea [(ngModel)]="tn.medizinische_hinweise" class="form-control" rows="5"></textarea>
    </div>
    <div class="form-group">
      <label class="primaryFontColor">Krankheiten</label>
      <textarea [(ngModel)]="tn.krankheiten" class="form-control" rows="5" ></textarea>
    </div>
  </div>
  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
    <h3 class="text-center primaryFontColor">Ernährungs­besonderheiten</h3>
    <hr>
    <h6 class="primaryFontColor">Bitte nur relevante Daten, die für eine Veranstalltung dienlich sind, eintragen. Datenschutzrichtlinie beachten!</h6>
    <div class="d-flex justify-content-center bigfont">
      <app-icon [icon]="Icon.ERNAEHRUNGSBESONDERHEITEN" [disabled]="!tn.ernaehrungsbesonderheiten"></app-icon>
    </div>
    <hr>
    <div class="form-group">
      <textarea [(ngModel)]="tn.ernaehrungsbesonderheiten" class="form-control" rows="5" ></textarea>
    </div>
  </div>
  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
    <h3 class="text-center primaryFontColor">Schwimmabzeichen</h3>
    <hr>
    <div class="form-group">
      <input type="text" list="schwimmabzeichenList" class="form-control" [(ngModel)]="tn.schwimmabzeichen" ngbTooltip="" placeholder="Bronze, Silber, Gold">

    </div>
  </div>
  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
    <h3 class="text-center primaryFontColor">Bemerkung</h3>
    <hr>
    <h6 class="primaryFontColor">Bitte nur relevante Daten, die für eine Veranstalltung dienlich sind, eintragen. Datenschutzrichtlinie beachten!</h6>
    <div class="form-group">
      <textarea [(ngModel)]="tn.weitere_bemerkungen" class="form-control" rows="5" ></textarea>
    </div>
  </div>

  <!-----------------------------Nachweise-------------------------------------->
  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
    <h3 class="text-center primaryFontColor">Nachweise</h3>
    <hr>
    <ng-template #createNachweise>
      <div>Nachweise können erst nach dem Speichern des Teilnehmers eingetragen werden.</div>
    </ng-template>
    <ng-container *ngIf="!!tn.id; else createNachweise">
      <app-edit-nachweise [tn]="tn"></app-edit-nachweise>
    </ng-container>
  </div>

  <div class="shadow pcBorder p-3 rounded grayBgColor mb-5 mt-3 d-block d-lg-none">
    <h3 class="text-center primaryFontColor">Erlaubnisse</h3>
    <hr>
    <ng-container *ngFor="let i of iconList; last as isLast">
      <div class="d-flex flex-row align-items-center" >
        <div class="flex-grow-1">
          {{iconMeta.get(i.icon)[tn[i.key]?2:3]}}
        </div>
        <div class="flex-grow-0">
          <app-icon-switch [options]="i.options" [(value)]="tn[i.key]" [showHint]="false"></app-icon-switch>
        </div>

      </div>
      <hr *ngIf="!isLast">
    </ng-container>
  </div>
</div>
