import {Component, OnInit} from '@angular/core';
import {ITeilnehmer} from 'src/app/models/ITeilnehmer.interface';
import {TeilnehmerService} from 'src/app/service/teilnehmer.service';
import {Location} from '@angular/common';
import {Gender} from 'src/app/models/gender.enum';
import {SessionService} from 'src/app/service/session.service';
import {ToastrService} from 'ngx-toastr';
import {IStamm} from 'src/app/models/IStamm.interface';
import {Rolle} from 'src/app/models/Rolle.enum';
import {StammService} from 'src/app/service/stamm.service';
import {TimePickerService} from 'src/app/service/time-picker-service.service';

@Component({
  selector: 'app-new-tn',
  templateUrl: './new-tn.component.html',
  styleUrls: ['./new-tn.component.scss']
})
export class NewTnComponent implements OnInit {

  public tn: ITeilnehmer;
  public iconList: string[];
  public Gender = Gender;
  public nutzerStaemme: IStamm[];

  constructor(
    private teilnehmerService: TeilnehmerService,
    private toast: ToastrService,
    private timePickerService: TimePickerService,
    private sessionService: SessionService,
    private location: Location,
    private stammService: StammService) {
    this.iconList = []; //teilnehmerService.iconList;
  }

  ngOnInit() {
    if(this.sessionService.session.currentPermission === Rolle.ADM || this.sessionService.session.currentPermission === Rolle.SU){
      this.nutzerStaemme = this.stammService.getStammListe();
    }else{
      this.nutzerStaemme = this.sessionService.session.nutzer.stamme;
    }
    this.tn = {
      id : null,
      stamm : this.nutzerStaemme[0],
      vorname : '',
      nachname : '',
      geburtsdatum : new Date("2010-1-1T00:00:00Z"),
      geschlecht : undefined,
      strasse : '',
      hausnummer : '',
      postleitzahl : '' as unknown as number,
      ort : '',
      zusatz : '',
      weitere_bemerkungen : '',
      handynummer : '',
      e_mail_adresse : '',
      schwimmabzeichen : '',
      medizinische_hinweise : '',
      ernaehrungsbesonderheiten : '',
      vegetarier : false,
      veganer : false,
      krankheiten : '',
      darf_unter_aufsicht_schwimmen : true,
      tetanus_impfung : true,
      lebensmittelunvertraeglichkeiten : '',
      per_vorname : '',
      per_nachname : '',
      per_strasse : '',
      per_hausnummer : '',
      per_postleitzahl : '' as unknown as number,
      per_ort : '',
      per_zusatz : '',
      per_telefonnummer : '',
      per_handynummer : '',
      per_e_mail_adresse : '',
      mitarbeiterstatus : false,
      zecken: true,
      medi_verabreichen: false,
      besondere_aktivitaeten: true,
      krankenversicherung: false,
      nachweise: []
    };
  }
  public validateDuplicate(){
    return this.teilnehmerService.getTnList().filter(tn => {
      return tn.vorname === this.tn.vorname &&
        tn.nachname === this.tn.nachname &&
        tn.geburtsdatum.getTime() === this.tn.geburtsdatum.getTime() &&
        tn.stamm.id === this.tn.stamm.id
    }).map(tn => tn.id)[0];
  }

  save() {
    if(this.tn.geschlecht === undefined) {
      this.toast.error("Es wurde kein Geschlecht ausgewählt.", "Fehler");
    }
    if(this.timePickerService.dateIsValid(this.tn.geburtsdatum)){
      this.teilnehmerService.createTn(this.tn).
      then(msg => {
        this.toast.success(msg, 'Erfolgreich');
        this.location.back();
      }).
      catch(msg => this.toast.error(msg, 'Fehler'));
    }else{
      this.toast.error("Ein Datum ist falsch eingegeben worden.", "Fehler");
    }
  }

  cancel() {
    console.log('Abbrechen');
    this.location.back();
  }



}
