import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BackendRequest, BackendRequestMethods, JsonOf } from '../utils/types/backendTypes';
import { isNutzer } from '../models/INutzer.interface';
import { isTeilnehmer } from '../models/ITeilnehmer.interface';
import { isVeranstaltung } from '../models/IVeranstaltung.interface';
import { isStamm } from '../models/IStamm.interface';
import { EventQueueService } from './event-queue-service.service';
import {isNachweisvorlage} from "../models/INachweisvorlage.interface";
import {isNachweis} from "../models/INachweis.interface";

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  private dbRevision = 0;

  constructor(private http: HttpClient,
    private eventQueue: EventQueueService) {

    setInterval(() => {
      this.updateDbRevision();
    }, 3000);
  }

  public static replaceDate(obj: any, propName: string) {
    if (typeof obj[propName] !== 'number') {
      throw new Error(propName + ' is not a Number: ' + obj[propName]);
    }
    obj[propName] = new Date(obj[propName]);
  }

  public static updateIdToProperty(obj: any, propName: string, getFn: (id: number) => any, isArray: boolean, sourceDataRevisionGetter:()=>number) {
    if (isArray) {
      let rev = null;
      const elemIds = obj[propName];
      const elemCache = [];
      delete obj[propName];
      Object.defineProperty(obj, propName, {
        get: () => {
          const newRevision = sourceDataRevisionGetter();
          if (rev !== newRevision) {
            elemCache.length = 0;
            elemIds.forEach(elemId => {
              elemCache.push(getFn(elemId));
            });
            rev = newRevision;
          }
          return elemCache;
        },
        enumerable: true
      });
    } else {
      const elemId = obj[propName];
      const cache = { data: undefined, rev: null };
      delete obj[propName];
      Object.defineProperty(obj, propName, {
        get: () => {
          const newRevision = sourceDataRevisionGetter();
          if (!cache.data || cache.rev !== newRevision) {
            cache.data = getFn(elemId);
            cache.rev = newRevision;
          }
          return cache.data;
        },
        set: val => {
          cache.data = val;
        },
        enumerable: true
      });
    }
  }

  public static toJsonField<T>(obj: T): JsonOf<T> {
    if (obj === null) {
      return null;
    }
    if (obj === undefined) {
      return "" as unknown as any;
    }
    if (typeof obj === 'boolean' || typeof obj === 'number' || typeof obj === 'string') {
      return obj as unknown as any;
    }
    if (typeof obj['getTime'] === 'function') {// Es ist ein Date
      return obj['getTime']();
    }
    if (isNutzer(obj) || isStamm(obj) || isTeilnehmer(obj) || isVeranstaltung(obj) || isNachweis(obj) || isNachweisvorlage(obj)) {
      return obj.id as unknown as any;
    }
    return obj as unknown as any;
  }

  public static toSearchJsonField<T>(obj: T): JsonOf<T> {
    if (obj === null) {
      return null;
    }
    if (obj === undefined) {
      return null;
    }
    if (typeof obj === 'boolean' || typeof obj === 'number' || typeof obj === 'string') {
      return obj as unknown as any;
    }
    if (typeof obj['getTime'] === 'function') {// Es ist ein Date
      return obj['getTime']();
    }
    if (isNutzer(obj) || isTeilnehmer(obj)) {
      return obj.id + " " + obj.vorname + " " + obj.nachname as unknown as any;
    }
    if (isStamm(obj)) {
      return obj.id + " " + obj.title + " " + obj.name as unknown as any;
    }
    if (isVeranstaltung(obj)) {
      return obj.id + " " + obj.titel as unknown as any;
    }
    return obj as unknown as any;
  }

  public static toJsonObj<T>(obj: T): JsonOf<T> {
    if (Array.isArray(obj)) {
      return obj.map(BackendService.toJsonObj) as unknown as JsonOf<T>;
    }
    const newObj: any = {};

    // tslint:disable-next-line: forin
    for (const key in obj) {
      const val = obj[key];
      if (Array.isArray(val)) {
        newObj[key] = val.map(BackendService.toJsonField);
      } else {
        newObj[key] = BackendService.toJsonField(val);
      }
    }

    return newObj;
  }

  public static toSearchJsonObj<T>(obj: T): JsonOf<T> {
    if (Array.isArray(obj)) {
      return obj.map(BackendService.toSearchJsonObj) as unknown as JsonOf<T>;
    }

    const newObj: any = {};
    // tslint:disable-next-line: forin
    for (const key in obj) {
      const val = obj[key];
      if (key === "icon" || key === "passworthash") {
        newObj[key] = "";
      } else if (Array.isArray(val)) {
        newObj[key] = val.map(BackendService.toSearchJsonField).join(" ");
      } else {
        newObj[key] = BackendService.toSearchJsonField(val);
      }
    }

    return newObj;
  }

  private toXml(key: string, value: string | boolean | number | undefined | Object | any[]) {
    if (Array.isArray(value)) {
      return this.arrayToXml(key, value);
    }
    if (typeof value === 'object') {
      return `<${key} xmlns="">${this.objectToXml(value)}</${key}>\n`;
    } else if (typeof value === 'string') {
      return `<${key} xmlns="">${this.escapeXml(value)}</${key}>\n`;
    }
    return `<${key} xmlns="">${value}</${key}>\n`;
  }

  private escapeXml(unsafe: string): string {
    return unsafe.replace(/[<>&'"]/g, (c) => {
      switch (c) {
        case '<': return '&lt;';
        case '>': return '&gt;';
        case '&': return '&amp;';
        case '\'': return '&apos;';
        case '"': return '&quot;';
      }
    });
  }

  private restoreXml(save: string): string {
    return save.replace(/&lt;|&gt;|&amp;|&apos;|&quot;/g, (c) => {
      switch (c) {
        case '&lt;': return '<';
        case '&gt;': return '>';
        case '&amp;': return '&';
        case '&quot;': return '"';
        case '&apos;': return '\'';
      }
    });
  }

  private arrayToXml(key: string, value: any[]) {
    let result = '';
    value.forEach((v) => {
      if (Array.isArray(v)) {
        result += `<${key} xmlns="">${this.arrayToXml(key, v)}</${key}>\n`;
      } else {
        result += this.toXml(key, v);
      }
    });
    return result;
  }

  private objectToXml(value: Object) {
    let result = '';

    if (value === null) {
      return null;
    }

    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        result += this.toXml(key, value[key]);
      }
    }

    return result;
  }

  public async handleWriteReturnTypeRequest<Method extends BackendRequestMethods, Request extends BackendRequest<Method>>(
    method: Method,
    params: Request['params'],
    triggerReload: Request['triggerReload']): Promise<string> {
    try {
      const data = await this.sendRequest(method, params, triggerReload) as any;

      if (!data.success) {
        throw new Error(data.error);
      }
      return data.error || 'Änderungen gespeichert';
    } catch (e) {
      return Promise.reject(e.message);
    }
  }

  public async sendRequest<Method extends BackendRequestMethods, Request extends BackendRequest<Method>>(
    method: Method,
    params: Request['params'],
    triggerReload: Request['triggerReload']): Promise<Request['returnType']> {
    console.debug('Send ' + method + ' Request: ', params);

    let data;
    try {
      let paramsString = '';
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          paramsString += this.toXml(key, params[key]);
        }
      }

      data = await this.http.post('/service',
        `<Envelope xmlns="http://schemas.xmlsoap.org/soap/envelope/">
          <Body>
              <${method} xmlns="http://regpEndpoint/">
                ${paramsString}
              </${method}>
          </Body>
      </Envelope>`,
        {
          responseType: 'text',
          headers: new HttpHeaders({
            'Accept': '*/*',
            'Content-Type': 'text/xml; charset=UTF-8'
          })
        }).toPromise();

      let firstObj = data.indexOf('{');
      let firstArray = data.indexOf('[');

      if (firstObj === -1) {
        firstObj = Number.MAX_SAFE_INTEGER;
      }
      if (firstArray === -1) {
        firstArray = Number.MAX_SAFE_INTEGER;
      }

      const isArray = firstObj > firstArray;

      data = data.substr(data.indexOf(isArray ? '[' : '{'));
      data = data.substr(0, data.lastIndexOf(isArray ? ']' : '}') + 1);

      const parse = JSON.parse(this.restoreXml(data));
      console.log('Parsed Response of Method: ' + method);
      return parse;
    } catch (err) {
      console.log('Failed to parse: ', data);
      throw new Error('Failed to parse (Method = ' + method + '): ' + data);
    } finally {
      if (triggerReload) {
        this.eventQueue.fireEvent('relaod');
      }
    }
  }

  public async updateDbRevision() {
    const newRevision = (await this.sendRequest("dbRevision", {}, false)).revision;
    if (this.dbRevision !== newRevision) {
      this.dbRevision = newRevision;
      this.eventQueue.fireEvent('relaod');
    }
  }
}
