import {Component, Input, OnChanges} from '@angular/core';
import {Gender} from "../../models/gender.enum";


export enum Icon {
  VEGETARIER,
  VEGANER,
  KEIN_VEGI,
  MAENNLICH,
  WEIBLICH,
  DIVERS,
  HANDYNUMMER,
  PER_TELEFONNUMMER,
  PER_HANDYNUMMER,
  E_MAIL_ADRESSE,
  PER_E_MAIL_ADRESSE,
  MEDIZINISCHE_HINWEISE,
  ERNAEHRUNGSBESONDERHEITEN,
  LEBENSMITTELUNVERTRAEGLICHKEITEN,
  KRANKHEITEN,
  DARF_UNTER_AUFSICHT_SCHWIMMEN,
  TETANUS_IMPFUNG,
  MITARBEITERSTATUS,
  VERANSTALTUNGSTYP_REGP,
  VERANSTALTUNGSTYP_LOCAL,
  VERANSTALTUNGSTYP_REGIO,
  ZECKEN,
  BESONDERE_AKTIVITAETEN,
  KRANKENVERSICHERUNG,
  MEDI_VERABREICHEN
}

export const IconMeta = new Map<Icon, [string, string, string, string]>([
  [Icon.VEGETARIER, ["fas fa-leaf", "icon-green", "Vegetarier", "Kein Veganer / Vegetarier"]],
  [Icon.VEGANER, ["fa fa-seedling", "icon-green", "Veganer", "Kein Veganer / Vegetarier"]],
  [Icon.KEIN_VEGI, ["", "icon-green", "Kein Veganer / Vegetarier", "Kein Veganer / Vegetarier"]],
  [Icon.MAENNLICH, ["fas fa-male", "icon-gray", Gender.MALE, Gender.MALE]],
  [Icon.WEIBLICH, ["fas fa-female", "icon-gray", Gender.FEMALE, Gender.FEMALE]],
  [Icon.DIVERS, ["fas fa-transgender", "icon-gray", Gender.DIVERS, Gender.DIVERS]],
  [Icon.HANDYNUMMER, ["fa fa-phone", "icon-blue", "Telefon", "Keine Telefonnummer"]],
  [Icon.PER_TELEFONNUMMER, ["fa fa-phone", "icon-blue", "Telefon", "Keine Telefonnummer"]],
  [Icon.PER_HANDYNUMMER, ["fas fa-mobile-alt", "icon-blue", "Handy", "Keine Handy"]],
  [Icon.E_MAIL_ADRESSE, ["far fa-envelope", "icon-blue", "E-Mail", "Keine E-Mail"]],
  [Icon.PER_E_MAIL_ADRESSE, ["far fa-envelope", "icon-blue", "E-Mail", "Keine E-Mail"]],
  [Icon.MEDIZINISCHE_HINWEISE, ["fas fa-pills", "icon-red", "Medizinische Hinweise", "Keine medizinischen Hinweise"]],
  [Icon.ERNAEHRUNGSBESONDERHEITEN, ["fas fa-balance-scale", "icon-red", "Ernährungsbesonderheiten", "Keine Ernährungsbesonderheiten"]],
  [Icon.LEBENSMITTELUNVERTRAEGLICHKEITEN, ["fas fa-balance-scale", "icon-red", "Ernährungsbesonderheiten", "Keine Ernährungsbesonderheiten"]],
  [Icon.KRANKHEITEN, ["fas fa-notes-medical", "icon-red", "Hat Krankheiten", "Hat keine Krankheiten"]],
  [Icon.DARF_UNTER_AUFSICHT_SCHWIMMEN, ["fas fa-swimmer", "icon-light-blue", "Darf unter Aufsicht schwimmen", "Darf nicht unter Aufsicht schwimmen"]],
  [Icon.TETANUS_IMPFUNG, ["fas fa-syringe", "icon-red", "Hat eine Tetanusimpfung", "Hat keine Tetanusimpfung"]],
  [Icon.MITARBEITERSTATUS, ["fas fa-star", "icon-yellow", "Mitarbeiter", "Kein Mitarbeiter"]],
  [Icon.VERANSTALTUNGSTYP_LOCAL, ["fas fa-user", "icon-dark-green", "Lokale Veranstaltung", "Lokale Veranstaltung"]],
  [Icon.VERANSTALTUNGSTYP_REGIO, ["fas fa-user-friends", "icon-dark-green", "Übergreifende Veranstaltung", "Übergreifende Veranstaltung"]],
  [Icon.VERANSTALTUNGSTYP_REGP, ["fas fa-users", "icon-dark-green", "REGP Veranstaltung", "REGP Veranstaltung"]],
  [Icon.ZECKEN, ["fas fa-bugs", "icon-gray", "Zecken dürfen entfernt werden", "Zecken dürfen nicht entfernt werden"]],
  [Icon.BESONDERE_AKTIVITAETEN, ["fa-brands fa-fort-awesome", "icon-gray", "Darf an besonderen Aktivitäten (Kanu, Klettern, ...) teilnehmen", "Darf nicht an besonderen Aktivitäten (Kanu, Klettern, ...) teilnehmen"]],
  [Icon.KRANKENVERSICHERUNG, ["fas fa-shield-virus", "icon-gray", "Auslands­krankenversicherung vorhanden", "Keine Auslands­krankenversicherung vorhanden"]],
  [Icon.MEDI_VERABREICHEN, ["fas fa-hand-holding-medical", "icon-red", "Verschreibungsfreie Medikamente dürfen verabreicht werden.", "Es dürfen keine Medikamente verabreicht werden."]]
]);


@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnChanges {
  @Input() public icon: Icon;
  @Input() public strike = false;
  @Input() public disabled:boolean = false;
  public helpText = "";
  public iconClass = "";

  constructor() {
  }

  ngOnChanges(): void {
    let iconMeta = IconMeta.get(this.icon);
    this.helpText = iconMeta[((this.strike || this.disabled) ? 3 : 2)];
    this.iconClass = iconMeta[0] + " " + (this.disabled? "icon-disabled" : iconMeta[1]);

  }

}
