import {Component, OnInit} from '@angular/core';
import {IVeranstaltung} from 'src/app/models/IVeranstaltung.interface';
import {IStamm} from 'src/app/models/IStamm.interface';
import {ActivatedRoute} from '@angular/router';
import {NutzerService} from 'src/app/service/nutzer.service';
import {StammService} from 'src/app/service/stamm.service';
import {Location} from '@angular/common';
import {VeranstaltungenService} from 'src/app/service/veranstaltungen.service';
import {ToastrService} from 'ngx-toastr';
import {SessionService} from 'src/app/service/session.service';
import {TimePickerService} from 'src/app/service/time-picker-service.service';

@Component({
  selector: 'app-new-event',
  templateUrl: './new-event.component.html',
  styleUrls: ['./new-event.component.scss']
})
export class NewEventComponent implements OnInit {
  public event: IVeranstaltung;
  public staemmeToAdd: IStamm[];

  //VA erweiterte Felder
  //public tnPreis: Number;
  //public maPreis: Number;
  //public exitKosten: Number;

  constructor(private route: ActivatedRoute,
    private toast: ToastrService,
    private location: Location,
    public nutzerService: NutzerService,
    private timePickerService: TimePickerService,
    private sessionService: SessionService,
    private veranstaltungenService: VeranstaltungenService,
    public stammService: StammService) {

      this.staemmeToAdd = [];
    }

  ngOnInit() {
    this.event = {
      id: null,
      titel: "",
      anfangsdatum: new Date(),
      enddatum: new Date(),
      ort: '',
      anmeldeschluss: new Date(),
      max_teilnehmeranzahl: 15,
      veranstaltungsmanager: [this.sessionService.session.nutzer],
      staemme: [],
      kurzbeschreibung: '',
      veranstaltungstyp: "0",
      veranstalter: this.sessionService.session.nutzer.stamme[0],
      teilnehmer: [],
      preis: 0,
      ausfallgebuehr: 0,
      mitarbeiter_preis: 0,
      maximale_einladungen: 10,
      kursleitung: "",
      anfangsuhrzeit: "",
      enduhrzeit: "",
      registerForm: "",
      pflichtfelder: "vorname;nachname;geburtsdatum",
      nachweise:""
    };
  }





  save() {
    console.log("Speichern");

    if(
      this.timePickerService.dateIsValid(this.event.anfangsdatum)&&
      this.timePickerService.dateIsValid(this.event.enddatum)&&
      this.timePickerService.dateIsValid(this.event.anmeldeschluss)
      ){
      this.veranstaltungenService.createVeranstaltung(this.event).
      then(msg=>{
        this.toast.success(msg, "Erfolgreich")
        this.location.back();
      }).
      catch(msg => this.toast.error(msg, "Fehler"));
    }else{
      this.toast.error("Ein Datum ist falsch eingegeben worden.", "Fehler");
    }
  }

  cancel() {
    console.log("Abbrechen");
    this.location.back();
  }

  public veranstalterToText(stamm: IStamm) {
    return stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "");
  }

  public veranstalterToHtml(stamm: IStamm) {
    const txt = stamm.name + (stamm.title ? (" (" + stamm.title + ")") : "");
    return `<img height="32" width="32" src="${stamm.icon}">${txt}`;
  }

  public veranstalterToId(stamm: IStamm) {
    return stamm.id;
  }

  private updateStaemmeToAdd(){
    if(this.event === undefined){
      console.warn("Unable to filter Stämme to Add, event is undefined");
      this.staemmeToAdd =  [];
    }
    this.staemmeToAdd = this.stammService.getStammListe().filter(stamm => {
      if(this.event === undefined){
        //Sollte nicht vorkommen
        return false;
      }

      return !this.event.staemme.some(s2 => {
        return s2.id == stamm.id;
      });
    });
  }

  public setVeranstallter(stamm: IStamm) {
    if(this.event === undefined){
      console.warn("Failed to update Veranstalter, Event is undefined");
      return;
    }

    this.event.veranstalter = stamm;
  }



  public addStamm(stamm: IStamm) {
    if(this.event === undefined){
      console.warn("Failed to add Stamm, Event is undefined");
      return;
    }

    if (stamm) {
      this.event.staemme.push(stamm);
    }
    this.updateStaemmeToAdd();
  }

  public dropStamm(stamm: IStamm) {
    if(this.event === undefined){
      console.warn("Failed to add Stamm, Event is undefined");
      return;
    }

    if (stamm) {
      this.event.staemme.splice(this.event.staemme.indexOf(stamm),1);
    }
    this.updateStaemmeToAdd();
  }


}
