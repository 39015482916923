import {Component, OnDestroy, OnInit} from '@angular/core';
import {NachweisvorlageService} from "../../service/nachweisvorlage.service";
import {INachweisvorlage} from "../../models/INachweisvorlage.interface";
import {ErinnerungZeit} from "../../models/erinnerungZeit.enum";
import {formateErinnerung, formatGueltigkeit} from "../../models/INachweis.interface";
import {GueltigkeitsdauerUnit} from "../../models/gueltigkeitsdauerUnit.enum";
import {UploadRequirement} from "../../models/uploadRequirement.enum";
import {EventQueueService} from "../../service/event-queue-service.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-view-nachweisvorlagen',
  templateUrl: './view-nachweisvorlagen.component.html',
  styleUrls: ['./view-nachweisvorlagen.component.scss']
})
export class ViewNachweisvorlagenComponent implements OnInit, OnDestroy {
  public readonly UploadRequirement = UploadRequirement;
  public readonly GueltigkeitsdauerUnit = GueltigkeitsdauerUnit;
  public readonly ErinnerungZeit = ErinnerungZeit;
  public vorlagen: INachweisvorlage[];
  public editId?: number;
  public add= false;
  public editVorlage?: INachweisvorlage;
  public loading = false;
  private sub?: Subscription;

  constructor(
    private readonly vorlagenService: NachweisvorlageService,
    private readonly eventQueue: EventQueueService
  ) { }

  ngOnInit(): void {
    this.vorlagen = this.vorlagenService.getNachweisvorlageList();
    this.sort();
    this.sub = this.eventQueue.getObservable().subscribe(evt => {
      if(evt === "initDone") {
        this.vorlagen = this.vorlagenService.getNachweisvorlageList();
        this.sort();
        this.loading = false;
      }
    });
  }

  private sort() {
    this.vorlagen.sort((v1, v2) => v1.name.localeCompare(v2.name));
    this.vorlagen.sort((v1, v2) => (v1.disabled ? 1:0) - (v2.disabled ? 1:0));
  }

  public ngOnDestroy(): void {
      this.sub.unsubscribe();
  }

  public onAddClick() {
    this.add = true;
    this.editId = undefined;
    this.editVorlage = {
      name: "",
      gueltigkeitsdauerUnit: '',
      gueltigkeitsdauerVal: 0,
      beschreibung: "",
      id: -1,
      erinnerungZeit: ErinnerungZeit.NONE,
      disabled: false,
      pflichtfeldMoeglich: false,
      uploadRequirement: UploadRequirement.NEVER
    };
  }

  public onEditClick(vorlage: INachweisvorlage) {
    this.add = false;
    this.editId = vorlage.id;
    this.editVorlage = {
      name: vorlage.name,
      gueltigkeitsdauerUnit: vorlage.gueltigkeitsdauerUnit ?? '',
      gueltigkeitsdauerVal: vorlage.gueltigkeitsdauerVal,
      beschreibung: vorlage.beschreibung,
      id: vorlage.id,
      erinnerungZeit: vorlage.erinnerungZeit,
      disabled: vorlage.disabled,
      pflichtfeldMoeglich: vorlage.pflichtfeldMoeglich,
      uploadRequirement: vorlage.uploadRequirement,
    }
  }

  public async onSaveClick() {
    if(this.add) {
      await this.vorlagenService.createVorlage(this.editVorlage)
    } else {
      await this.vorlagenService.updateVorlage(this.editVorlage);
    }
    this.loading = true;

    this.add = false;
    this.editId = undefined;
    this.editVorlage = null;
  }

  public formateErinnerung(erinnerungZeit: ErinnerungZeit) {
      return formateErinnerung(erinnerungZeit)
  }

  public formatGueltigkeit(v: INachweisvorlage) {
      return formatGueltigkeit(v)
  }

}
