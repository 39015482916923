import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { INutzer } from 'src/app/models/INutzer.interface';
import { SessionService } from 'src/app/service/session.service';
import { Rolle } from 'src/app/models/Rolle.enum';
import { EventQueueService } from 'src/app/service/event-queue-service.service';

@Component({
  selector: 'app-nav-settings',
  templateUrl: './nav-settings.component.html',
  styleUrls: ['./nav-settings.component.scss']
})
export class NavSettingsComponent implements OnInit {


  @Output() public selectedElement = new EventEmitter<string>();
  public currentElement = "";
  public currentUser: INutzer;
  public Rolle = Rolle;
  public isSU = false;
  public isAdm = false;
  public options = [
    {
      icon: "fas fa-user-cog",
      name: "Benutzer",
      key: "Benutzer",
      needPermission: false,
      needAdm: false,
      showOnMobile: true
    },
    {
      icon: "fas fa-gopuram",
      name: "Stamm",
      key: "Stamm",
      needPermission: true,
      needAdm: false,
      showOnMobile: false
    },
    {
      icon: "fas fa-users-cog",
      name: "Benutzerverwaltung",
      key: "Benutzerverwaltung",
      needPermission: true,
      needAdm: false,
      showOnMobile: true
    },
    {
      icon: "fas fa-file-invoice",
      name: "Nachweisvorlagen",
      key: "Nachweisvorlagen",
      needPermission: true,
      needAdm: false,
      showOnMobile: true
    },
    {
      icon: "fas fa-users-cog",
      name: "Statistics",
      key: "Statistics",
      needPermission: true,
      needAdm: true,
      showOnMobile: false
    }
  ];



  constructor(
    public sessionService: SessionService,
    private eventQueue: EventQueueService
  ) {
    this.selectedElement.subscribe((elem) => {
      this.currentElement = elem;
      console.log("Selected: " + elem);
    });

    this.eventQueue.getObservable().subscribe(evt => {
      if (evt === "initDone") {
        this.currentUser = this.sessionService.session.nutzer;
        this.isSU = this.sessionService.session.currentPermission === Rolle.ADM || this.sessionService.session.currentPermission === Rolle.SU;
      }
    });
  }

  ngOnInit() {
    this.currentUser = this.sessionService.session.nutzer;
    this.selectedElement.emit("Benutzer");
    this.isSU = this.sessionService.session.currentPermission === Rolle.ADM || this.sessionService.session.currentPermission === Rolle.SU;
    this.isAdm = this.sessionService.session.currentPermission === Rolle.ADM;
  }

  getOption(element : string) : {
    icon: string,
    name: string,
    key: string,
    needPermission: boolean,
    needAdm: boolean,
    showOnMobile: boolean
  }{
    return this.options.find(e => e.key === element);
  }

}
