import {Injectable} from '@angular/core';
import {JsonOf} from "../utils/types/backendTypes";
import {BackendService} from "./backend.service";
import {SessionService} from "./session.service";
import {INachweis} from "../models/INachweis.interface";

@Injectable({
  providedIn: 'root'
})
export class NachweisService {

  private revision: number = -1;
  private nachweise: INachweis[] = [];


  constructor(private backend: BackendService,
              private sessionService: SessionService) {
  }

  public static fromJsonObj<T>(obj: JsonOf<T>): T {
    BackendService.updateIdToProperty(obj, "teilnehmer", (id) => (window as any).teilnehmerService.getTn(id), false, () => (window as any).teilnehmerService.getRevision());
    BackendService.updateIdToProperty(obj, "vorlage", (id) => (window as any).nachweisvorlagenService.getNachweisvorlage(id), false, () => (window as any).nachweisvorlagenService.getRevision())
    BackendService.replaceDate(obj, "erstellt");
    BackendService.replaceDate(obj, "ausstellungsdatum");
    return obj as unknown as T;
  }

  public updateData(): Promise<INachweis[]> {
    const data = this.backend.sendRequest("getNachweis", {
      sessionId: this.sessionService.session.sessionId
    }, false);

    const result = data.then(d => {
      if (d === null) {
        return [];
      }

      return d.map(NachweisService.fromJsonObj);
    }).catch(err => {
      console.log("Failed to load Nachweise");
      console.log(err);
    }).then(d => {
      this.nachweise = d as unknown as INachweis[];
      this.revision++;
      return this.nachweise;
    });
    return result;
  }

  public getNachweisList(): INachweis[] {
    return this.nachweise;
  }

  public getNachweis(id: number) {
    return this.nachweise.find(n => n.id === id);
  }

  public updateNachweis(nachweis: INachweis, dokument: string | null, ignoreReload?: boolean): Promise<string> {
    try {
      console.log("updateNachweisToJSON: ");
      console.log(BackendService.toJsonObj(nachweis));
      console.log("updateNachweisObject: ")
      console.log(nachweis);
      const data = this.backend.sendRequest("updateNachweis", {
        nachweis: BackendService.toJsonObj(nachweis),
        dokument: dokument ?? '',
        sessionId: this.sessionService.session.sessionId
      }, !ignoreReload as unknown as true);
      return data.then(msg => {
        return new Promise<string>((resolve, reject) => {
          if (msg.success === false) {
            reject(msg.error);
          }
          resolve("Änderungen gespeichert");
        })
      });
    } catch (e) {
      return Promise.reject(e.message)
    }
  }

  /**
   * ID wird nicht mit geschrieben
   */
  public async createNachweis(nachweis: INachweis, dokument: string = "", ignoreReload = false): Promise<string> {
    const createResult = await this.backend.sendRequest("createNachweis", {
      nachweis: BackendService.toJsonObj(nachweis),
      dokument: dokument,
      sessionId: this.sessionService.session.sessionId
    }, !ignoreReload as unknown as true);

    if(createResult.success){
      return "Nachweis erfolgreich angelegt";
    } else {
      throw createResult.error;
    }
  }

  public async deleteNachweis(nachweis: INachweis): Promise<string>{
    const deleteResult = await this.backend.sendRequest("deleteNachweis", {
      id: nachweis.id,
      sessionId: this.sessionService.session.sessionId
    }, true);

    if(deleteResult.success){
      return "Nachweis gelöscht";
    } else {
      throw deleteResult.error;
    }
  }

  public getRevision(): number {
    return this.revision;
  }

  public getNachweisDokument(nachweis: INachweis): Promise<string> {
    return this.backend.sendRequest("getNachweisDokument", {
      sessionId: this.sessionService.session.sessionId,
      id: nachweis.id
    }, false).then(f => f.file);
  }
}
